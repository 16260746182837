<template>
  <div class="autumnOctPC">
    <div class="banner">
      <img src="/activity/autumnOct/banner.png" alt="">
    </div>
    <div class="part-1">
      <img src="/activity/autumnOct/tit1.png" alt="">
      <div class="part1-centent">
        <ul>
          <li @mouseover="hoverLi(0)" :class="hoverControl == 0 ? 'hoverLi1' : '' " 
          @mouseleave="noHover">
            <p>
              十月，是我国的收货之季，一片片稻田闪烁着金色的光芒、饱满的硕果正摇摇欲坠，秋菊绽放、桂花飘香，金黄色的落叶在空中飘舞，人们脸上洋溢着幸福的笑容；
            </p>
          </li>
          <li @mouseover="hoverLi(1)" :class="hoverControl == 1 ? 'hoverLi2' : '' "
          @mouseleave="noHover">
            <p>
              十月，也是黄金需求旺盛之月，十月的日子总寓意着十全十美，大吉大利，是婚嫁和新店开张的好时节，此时黄金制品总是用来作为美好祝福的象征；
            </p>
          </li>
          <li @mouseover="hoverLi(2)" :class="hoverControl == 2 ? 'hoverLi3' : '' "
          @mouseleave="noHover">
            <p>
              十月，还是贵金属产品的投资旺季，人们会在这个丰收之月开始新一年的投资计划，因现货黄金具有起点低、双向交易、易上手等特点，所以“炒金”成为越来越多数人的选择；
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="part-2">
      <img class="part2tit" src="/activity/autumnOct/tit2.png" alt="">
      <div class="part2-centent">
        <ul>
          <li>
            <img src="/activity/autumnOct/part2start.png" alt="">
            <div>
              <p>
                <span>0</span>
                <span>佣金</span>
              </p>
              <p>
                <span>0</span>
                <span>开户费</span>
              </p>
              <p>
                <span>0.01</span>
                <span>手起投</span>
              </p>
              <p style="opacity:0">
                <span>0.01</span>
                <span>手起投</span>
              </p>
            </div>
          </li>
          <li>
            <img src="/activity/autumnOct/part2time.png" alt="">
            <div>
              <p>
                <span>1</span>
                <span>键下单</span>
              </p>
              <p>
                <span>1</span>
                <span>秒平仓</span>
              </p>
              <p>
                <span>1</span>
                <span>分钟看行情</span>
              </p>
              <p>
                <span>1</span>
                <span>周5 x 24小时可交易</span>
              </p>
            </div>
          </li>
          <li>
            <img src="/activity/autumnOct/part2news.png" alt="">
            <div>
              <p>
                <span></span>
                <span>每天两次解盘</span>
              </p>
              <p>
                <span></span>
                <span>名师在线指导</span>
              </p>
              <p>
                <span></span>
                <span>专业投资建议</span>
              </p>
              <p>
                <span>300万+</span>
                <span>投资者交流社群</span>
              </p>
            </div>
          </li>
        </ul>
        <p class="part2text">
          小本炒黄金<br>
          给自己的人生，多一个机会；给自己的家人，多一份交代；
        </p>
        <img @click="openUser" src="/activity/autumnOct/part2Button.png" alt="">
      </div>
    </div>
    <div class="part-3">
      <img class="part3tit" src="/activity/autumnOct/tit3.png" alt="">
      <div class="part3-centent">
        <span href="#" @click="openPop">活动详情</span>
        <div class="imgbox">
          <img src="/activity/autumnOct/part3text.png" alt="">
          <img src="/activity/autumnOct/part3gift.png" alt="">
        </div>
        <img @click="openUser" class="part3Button" src="/activity/autumnOct/part3Button.png" alt="">
        <p>名额有限，先到先得</p>
      </div>
    </div>
    <div class="part-4">
      <img class="part4tit" src="/activity/autumnOct/tit4.png" alt="">
      <div class="part4-centent">
        <div class="redPackage">
          <img @click="openUser" src="/activity/autumnOct/part4Button.png" alt="">
        </div>
      </div>
    </div>
    <div class="part-5">
      <img class="part5tit" src="/activity/autumnOct/tit5.png" alt="">
      <div class="part5-centent">
        <p>
          专业权威机构监管<br>
          香港金银业贸易场第081号行员
        </p>
        <div class="imgBox">
          <img src="/activity/autumnOct/part5-1.png" alt="">
          <img src="/activity/autumnOct/part5-2.png" alt="">
        </div>
        <div class="part5text">

        </div>
      </div>
    </div>
    <div class="popup" v-if="controlPop">
      <img class="guanbi1" @click="closePop" src="/activity/autumnOct/guanbi1.png" alt="">
      <ul class="popContent">
        <li>
          <span>活动时间：</span>
          即日起至2022年10月31日23:59
        </li>
        <li>
          <span>活动对象：</span>
          首次在本公司真实交易账户入金的客户
        </li>
        <li>
          <span>活动规则：</span><br>         
          活动期间，新用户首次入金最高可领$7500+￥500微信红包<br>
          ①新客开户即领300美元现金券;<br>
          ②首次入金立即到账100美元交易现金+与入金金额对应赠金点回馈;<br>
          ③入金后咨询客服领取最高￥500微信红包;<br>
        </li>
        <li>
          <span>奖励列表：</span><br>
          <table>
            <tr>
              <th>赠送条件（美元）</th>
              <th>赠金明细</th>
              <th>总金额</th>
            </tr>
            <tr>
              <th>开立真实账户</th>
              <th>$300（现金券）+$100（赠金点）</th>
              <th>400美元</th>
            </tr>
            <tr>
              <th>首入金≥200</th>
              <th>$300（现金券）+$100（交易现金）+$200（赠金点）+￥68（微信红包）</th>
              <th>600美元+68RMB</th>
            </tr>
            <tr>
              <th>首入金≥500</th>
              <th>$300（现金券）+$100（交易现金）+$200（赠金点）+￥200（微信红包）</th>
              <th>600美元+200RMB</th>
            </tr>
            <tr>
              <th>首入金≥1000</th>
              <th>$300（现金券）+$100（交易现金）+$200（赠金点）+￥500（微信红包）</th>
              <th>600美元+500RMB</th>
            </tr>
            <tr>
              <th>首入金≥5000</th>
              <th>$300（现金券）+$100（交易现金）+$500（赠金点）+￥500（微信红包）</th>
              <th>900美元+500RMB</th>
            </tr>
            <tr>
              <th>首入金≥10000</th>
              <th>$300（现金券）+$100（交易现金）+$1100（赠金点）+￥500（微信红包）</th>
              <th>1500美元+500RMB</th>
            </tr>
            <tr>
              <th>首入金≥15000</th>
              <th>$300（现金券）+$100（交易现金）+$3200（赠金点）+￥500（微信红包）</th>
              <th>3500美元+500RMB</th>
            </tr>
          </table>
          同一身份信息仅可领取一次，赠金将在注册/入金后自动发放；
        </li>
        <li>
          <span>奖励说明：</span><br>
          <span>美元现金券</span><br>
        </li>
        <li>
          活动期间内，开立真实账户赠送100美元现金券①+100美元现金券②+100美元现金券③
        </li>
        <li>现金券使用规则：</li>
        <li>
          1、现金券将在开户当天开始分别发放，现金券①在开户当天激活；现金券②在开户后的第一天激活；现金券③在开户后的第4天激活；<br>
          2、现金券激活成后可直接交易，激活后使用有效期从当日00:00起计算，共计3天；<br>
          3、每张现金券仅可交易1笔，现金券持仓单需在最近一次04:00前平仓，或由系统自动平仓；<br>
          4、现金券①领券后，平仓盈利部分需满足入金≥100美元后方可提取；现金券②领券后，平仓盈利部分需满足入金≥200美元后方可提取，亏损部分由平台承担；现金券③领券后，平仓盈利部分需满足入金≥300美元后方可提取，亏损部分由平台承担，现金券止盈4%；<br>
          5、盈利有效期为平仓后30天内，逾期扣回；<br>
          6、现金券交易不计入活动手数。<br>
          7、现金券仅限现货黄金/白银交易品种。
        </li>
        <li>
          <span>赠金点</span>
        </li>
        <li>
          1、活动期间内，开立真实账户或首次入金≥对应金额，即可获得对应金额赠金点回馈；<br>
          2、满足赠金点领取条件后，赠金点将会发放至用户中心，客户按照不同账户类型，每交易一手现货黄金/白银，返还相对应赠金金额至用户MT4真实交易账户中（未满一手按照实际交易手数占一手的比例计算）；<br>
          3、次日交易结算时，按当天完成的交易手数计算出相应的可提取赠金；<br>
          4、可提取赠金需在用户中心进行提取，提取后返还至客户MT4真实交易账户中，赠金可提现/交易；<br>
          5、不同账户类型赠金点返还额度如下：<br>
        </li>
        <li>
          <img src="/activity/autumnOct/popImg.png" alt="">
        </li>
        <li>
          <span>交易现金</span><br>
          1、活动期间内，首次入金≥对应金额，即送100美元现金，美元现金可直接用于交易，自首次入金起30天内，用户交易满足6手黄金/白银手数可提现；<br>
          2、用户满足美元现金领取条件后，系统将会直接发放100美元现金至MT4真实交易账户。<br>
          举例：①首次入金≥200美元，即可获赠100美元交易现金，30天内交易满6手可提现。
        </li>
        <li>
          <span>微信现金红包</span><br>
          活动期间内，首次入金≥对应金额，即可联系客服咨询领取微信红包。<br>
        </li>
        <li>
          <span>注意事项</span><br>
          1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br>
          2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br>
          3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br>
          4. 用户领取100美元交易现金, 如在30天内交易手数未满6手或未满足交易手数前进行出金，则此时平台直接扣除100美元交易现金，活动最终结算时交易账户中若仍有持仓单，若发生亏损情况，本公司将不承担任何责任；<br>
          5. 在活动有效期内，不管客户盈利或亏损，只要客户不主动终止参与本活动。如客户多次入金，都将在活动结算时统一结算；<br>
          6. 在活动有效期内，客户可在用户中心主动终止参与本活动。终止参与本活动后，本公司平台将会按活动规则进行结算；<br>
          7. 凡推广商及其名下客户不参与本活动；<br>
          8. 如本公司怀疑客户滥用或以其他方式滥用优惠，进行异常操作（如对冲订单，多账号操作等），本公司有权停止客户新交易或停用账号且取消账户所获赠金及利润及取消所有交易，并视乎情况扣除账户结余；<br>
          9. 使用本公司赠送现金券进行交易将不计入活动手数；<br>
          10. 本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。<br>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name:'autumnOctPC',
  data() {
    return {
      hoverControl:'',
      controlPop:false,
    }
  },
  methods:{
    openUser(){
      window.open('https://www.rlcltd001.com/uc/signUp/real');
    },
    hoverLi(attr){
      this.hoverControl = attr;
    },
    noHover(){
      this.hoverControl = 4;
    },
    openPop(){
      this.controlPop = true;
    },
    closePop(){
      this.controlPop = false;
    }
  },
  created(){
    if(document.body.clientWidth<500){
      // this.$router.push({
      //   name:'autumnOctWap',
      // })
      window.location.href = 'https://m.jvjtjqr.com/#/activity/autumnOct/wap';
    }
  }
}
</script>

<style lang="less" scoped>
  img{
    display: block;
  }
  .autumnOctPC{
    width: 100%;
    .banner{
      width: 100%;
      img{
        width: 100%;
      }
    }
    .part-1{
      width: 100%;
      img{
        width: 100%;
      }
      .part1-centent{
        width: 100%;
        height: 1060px;
        background: url('/activity/autumnOct/background1.jpg') no-repeat;
        background-size: cover;
        ul{
          width: 1328px;
          height: auto;
          margin: 0 auto;
          box-sizing: border-box;
          padding-top: 60px;
          li{
            width: 100%;
            height: 280px;
            background: url('/activity/autumnOct/part-1-1.1.png') no-repeat;
            background-size: cover;
            display: flex;
            p{
              width: 580px;
              font-size: 22px;
              color: #a86e4f;
              text-align: justify;
              margin-right: 40px;
              line-height: 40px;
            }
          }
          li:nth-child(1){
            justify-content: flex-end;
            align-items: center;
          }
          li:nth-child(2){
            background: url('/activity/autumnOct/part-1-2.1.png') no-repeat;
            background-size: cover;
            margin: 50px 0;
            justify-content: flex-start;
            align-items: center;
            p{
              margin-right: 0px;
              margin-left: 40px;
            }
          }
          li:nth-child(3){
            background: url('/activity/autumnOct/part-1-3.1.png') no-repeat;
            background-size: cover;
            justify-content: flex-end;
            align-items: center;
          }
          .hoverLi1{
            background: url('/activity/autumnOct/part-1-1.2.png') no-repeat;
            background-size: cover;
            p{
              color: #ffffff;
            }
          }
          .hoverLi2{
            background: url('/activity/autumnOct/part-1-2.2.png') no-repeat !important;
            background-size: cover;
            p{
              color: #ffffff;
            }
          }
          .hoverLi3{
            background: url('/activity/autumnOct/part-1-3.2.png') no-repeat !important;
            background-size: cover;
            p{
              color: #ffffff;
            }
          }
        }
      }
    }
    .part-2{
      width: 100%;
      .part2tit{
        width: 100%;
      }
      .part2-centent{
        width: 100%;
        height: 985px;
        background: url('/activity/autumnOct/background2.jpg') no-repeat;
        background-size: cover;
        box-sizing: border-box;
        padding-top: 80px;
        ul{
          width: 1330px;
          height: 640px;
          margin: 0 auto;
          display: flex;
          // justify-content: center;
          // align-items: center;
          li{
            width: 800px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            img{
              width: 240px;
              margin: 0;
              margin-bottom: 45px;
            }
            div{
              width: 100%;
              box-sizing: border-box;
              p{
                line-height: 60px;
                box-sizing: border-box;
                padding-left: 70px;
                span:nth-child(1){
                  font-size: 44px;
                  color: #e34a3f;
                }
                span:nth-child(2){
                  font-size: 27px;
                  font-weight: 510;
                  color: #a86e4f;
                }
              }
              p:last-child{
                width: 410px;
              }
            }
          }
          li:nth-child(2){
            margin: 0 50px 0 50px;
          }
        }
        .part2text{
          text-align: center;
          color: #a86e4f;
          font-size: 22px;
          line-height: 40px;
        }
        img{
          margin: 40px auto 0;
          cursor: pointer;
        }
      }
    }
    .part-3{
      width: 100%;
      .part3tit{
        width: 100%;
      }
      .part3-centent{
        width: 100%;
        height: 850px;
        background: url('/activity/autumnOct/background3.jpg') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-top: 70px;
        .imgbox{
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            margin: 0 60px;
          }
        }
        .part3Button{
          width: 360px;
          margin-top: 150px;
          cursor: pointer;
        }
        span{
          font-size: 20px;
          text-decoration: underline;
          color: #c9363a;
          margin-bottom: 30px;
          cursor: pointer;
        }
        p{
          color:#a86e4f;
          font-size: 22px;
          margin-top: 30px;
        }
      }
    }
    .part-4{
      width: 100%;
      .part4tit{
        width: 100%;
      }
      .part4-centent{
        width: 100%;
        height: 855px;
        background: url('/activity/autumnOct/background4.jpg') no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        .redPackage{
          width: 894px;
          height: 635px;
          background: url('/activity/autumnOct/part4Red.png') no-repeat;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          box-sizing: border-box;
          padding-bottom: 150px;
          img{
            cursor: pointer;
          }
        }
      }
    }
    .part-5{
      width: 100%;
      .part5tit{
        width: 100%;
      }
      .part5-centent{
        width: 100%;
        height: 1349px;
        background: url('/activity/autumnOct/background5.jpg') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-top: 55px;
        p{
          color:#a86e4f;
          font-size: 22px;
          text-align: center;
          line-height: 40px;
        }
        .imgBox{
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 50px auto 60px;
          img{
            margin: 0 25px;
          }
        }
        .part5text{
          width: 1328px;
          height: 462px;
          background: url('/activity/autumnOct/part5-cc.png') no-repeat;
          background-size: cover;
        }
      }
    }
    .popup{
      width: 1100px;
      height: 900px;
      border-radius: 20px;
      background: #fdf5e6;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%;-50%);
      box-sizing: border-box;
      padding: 10px;
      box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
      overflow-y: scroll;
      z-index:99999999999999;
      .guanbi1{
        float: right;
        cursor: pointer;
        margin-top: 8px;
        margin-right: 10px;
      }
      .popContent{
        box-sizing: border-box;
        padding: 40px 50px 20px;
        li{
          font-size: 18px;
          color: #a86e4f;
          line-height: 36px;
          span{
            font-weight: 600;
            color: #e34a3f;
          }
          img{
            width: 100%;
            height: auto;
            margin: 20px 0;
          }
          table{
            border: 1px solid #e34a3f;
            border-radius: 15px;
            tr{
              th{
                text-align: center;
                font-weight: 500;
                line-height: 50px;
                border-bottom: 1px solid #d2a765;
                border-right: 1px solid #d2a765;
                padding: 5px;
              }
              th:last-child{
                border-right: 1px solid transparent;
              }
            }
            tr:nth-child(1){
              th{
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: none;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: none;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: none;
  }
  
  ::-webkit-scrollbar-thumb:active {
    background-color: none;
  }
</style>